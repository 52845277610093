import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const BlogRollTemplate = (props) => {
    const { data } = props;
    const { edges: posts } = data.allMarkdownRemark;

    const columnClasses = 'column card m-3 p-3 sitteroo';

    return (
        <div className="columns">
            {posts &&
                posts.map(({ node: post }, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={columnClasses} key={`blog-${idx}`}>
                        <div className="card-image">
                            <figure className="image">
                                <PreviewCompatibleImage
                                    imageInfo={{
                                        image: post.frontmatter.featuredimage,
                                        alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                                        width: post.frontmatter.featuredimage
                                            .childImageSharp.gatsbyImageData
                                            .width,
                                        height: post.frontmatter.featuredimage
                                            .childImageSharp.gatsbyImageData
                                            .height,
                                    }}
                                />
                            </figure>
                        </div>
                        <div className="card-content">
                            <div className="media">
                                <div className="media-content text-cut">
                                    <h3>
                                        <Link
                                            className="sitteroo-blog-list-heading"
                                            to={post.fields.slug}
                                        >
                                            {post.frontmatter.title}
                                        </Link>
                                    </h3>
                                </div>
                            </div>

                            <div className="content text-cut">
                                {post.frontmatter.description}
                                <br />
                            </div>
                        </div>

                        <footer className="card-footer">
                            <div className="card-footer-item is-justify-content-flex-end is-justify-content-center-mobile">
                                <Link
                                    className="button is-rounded is-large is-responsive bg-salmon is-primary"
                                    to={post.fields.slug}
                                >
                                    read more
                                    <span className="is-sr-only">
                                        {post.frontmatter.description}
                                    </span>
                                </Link>
                            </div>
                        </footer>
                    </div>
                ))}
        </div>
    );
};

BlogRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            // eslint-disable-next-line react/forbid-prop-types
            edges: PropTypes.array,
        }),
    }),
};

export default function BlogRoll() {
    return (
        <StaticQuery
            query={graphql`
                query BlogRollQuery {
                    allMarkdownRemark(
                        sort: { fields: [frontmatter___date], order: DESC }
                        filter: {
                            frontmatter: {
                                templateKey: { eq: "blog-post" }
                                featuredpost: { eq: true }
                            }
                        }
                        limit: 3
                    ) {
                        edges {
                            node {
                                excerpt(pruneLength: 100)
                                id
                                fields {
                                    slug
                                }
                                frontmatter {
                                    title
                                    templateKey
                                    date(formatString: "MMMM DD, YYYY")
                                    description
                                    featuredpost
                                    featuredimage {
                                        childImageSharp {
                                            gatsbyImageData(
                                                width: 447
                                                quality: 100
                                                layout: CONSTRAINED
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={(data, count) => (
                <BlogRollTemplate data={data} count={count} />
            )}
        />
    );
}
