/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import BlogRoll from '../components/BlogRoll';
import Contact from '../components/Contact';
import { Helmet } from 'react-helmet';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

export const IndexPageTemplate = ({ images, mainpitch, helmet }) => (
    <>
        {helmet || ''}
        <section className="hero section--orange">
            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        <div className="column has-text-centered">
                            <PreviewCompatibleImage
                                imageInfo={{
                                    image: images[1].node,
                                }}
                            />
                        </div>
                        <div className="column has-text-centered-mobile mainpitch">
                            <h1 className="sitteroo-h2 margin-left-30">
                                Where children
                                <p className="sitteroo-monday-picnic salmon has-text-right">
                                    comes first
                                </p>
                            </h1>
                            <p className="subtitle has-text-white">
                                {mainpitch.description}
                            </p>
                            <Link
                                className="sitteroo-button bg-blue is-pulled-right"
                                to="/about/"
                            >
                                <span className="is-sr-only">
                                    Find out more about Sitteroo
                                </span>
                                read more
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="hero section--green section--with-mask mask-one">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered is-vcentered">
                        <div className="column has-text-centered">
                            <h2 className="sitteroo-h2">
                                From sharing helpful tips and inspiration
                                <p className="sitteroo-monday-picnic yellow">
                                    to support you in the many challenges of
                                    parenthood
                                </p>
                            </h2>
                            <Link
                                className="sitteroo-button bg-salmon"
                                to="/blog/"
                            >
                                read blog
                            </Link>
                        </div>
                        <div className="column has-text-centered">
                            <PreviewCompatibleImage
                                imageInfo={{
                                    image: images[2].node,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="hero section--pastel section--with-mask mask mask-two">
            <div className="container">
                <h2 className="sitteroo-monday-picnic yellow has-text-left pt-4">
                    Latest posts
                </h2>
                <div className="pb-6">
                    <BlogRoll />
                </div>
            </div>
        </section>

        <section className="hero section--salmon section--with-mask mask mask-three">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered is-vcentered">
                        <div className="column has-text-centered">
                            <h2 className="sitteroo-h2 has-text-left">
                                sign up for our newsletter
                                <p className="sitteroo-monday-picnic brown has-text-right">
                                    and get the free ebook!
                                </p>
                            </h2>
                            <div
                                className="container"
                                style={{ maxWidth: '400px' }}
                            >
                                <Contact />
                            </div>
                        </div>
                        <div className="column has-text-centered">
                            <PreviewCompatibleImage
                                className="image"
                                imageInfo={{
                                    image: images[0].node,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
);

IndexPageTemplate.propTypes = {
    mainpitch: PropTypes.object,
};

const IndexPage = ({ data }) => {
    const {
        data: { frontmatter },
        images: { edges },
    } = data;

    return (
        <Layout>
            <IndexPageTemplate
                mainpitch={frontmatter.mainpitch}
                images={edges}
                helmet={<Helmet titleTemplate="%s" />}
            />
        </Layout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
};

export default IndexPage;

export const pageQuery = graphql`
    query IndexPageTemplate {
        data: markdownRemark(
            frontmatter: { templateKey: { eq: "index-page" } }
        ) {
            frontmatter {
                mainpitch {
                    description
                }
            }
        }
        images: allFile(
            filter: {
                extension: { regex: "/(png)/" }
                name: { regex: "/(section-one-img|section-two-img|ebook)/" }
            }
            sort: { fields: name }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(
                            width: 450
                            quality: 100
                            layout: CONSTRAINED
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
    }
`;
